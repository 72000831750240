import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { all_routes } from "../../router/all_routes";
import { useAuthContext } from "../../../context/AuthContext";
import * as Yup from "yup";
import { updateUser } from "../../../api/auth";
import toast from "react-hot-toast";

const UserSettings = () => {
  const routes = all_routes;
  const { user, setUser, authToken } = useAuthContext(); // Assuming user context has user data

  // Formik for user profile data
  const profileFormik = useFormik({
    initialValues: {
      name: user?.name || "",
      phone: user?.phone || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Emri është i kërkuar!"),
      phone: Yup.string().required("Numri është i kërkuar!"),
    }),
    onSubmit: async (values) => {
      const promise = updateUser(authToken, values);

      await toast.promise(promise, {
        loading: "Duke ndryshuar profilin...",
        success: () => {
          setUser({ ...user, ...values });
          return "Profili u ndryshua me sukses!";
        },
        error: (err) => {
          console.error(err);

          return "Pati problem në ndryshimin e profilit!";
        },
      });
    },
  });

  useEffect(() => {
    profileFormik.setValues({
      name: user?.name || "",
      phone: user?.phone || "",
    });
  }, [user]);

  return (
    <div className="min-h-[85vh]">
      {/* Breadcrumb Section */}
      <div className="breadcrumb-bar">
        <div className="container">
          <div className="row align-items-center text-center">
            <div className="col-md-12 col-12">
              <h2 className="breadcrumb-title">Profili</h2>
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={routes.homeOne}>Ballina</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Profili
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div className="content">
        <div className="container">
          <div className="row">
            {/* Profile Data Form */}
            <div className="col-lg-12">
              <div className="settings-info">
                <div className="profile-info-grid">
                  <div className="profile-info-header">
                    <h5>Ndrysho Të Dhënat e Tua</h5>
                    <p>Modifikoni të dhënat personale për profilin tuaj.</p>
                  </div>
                  <div className="profile-inner">
                    <form onSubmit={profileFormik.handleSubmit}>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="profile-form-group">
                            <label>
                              Emri <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className={`form-control ${
                                profileFormik.touched.name &&
                                profileFormik.errors.name
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name="name"
                              value={profileFormik.values.name}
                              onChange={profileFormik.handleChange}
                              onBlur={profileFormik.handleBlur}
                              placeholder="Shkruaj emrin tuaj..."
                            />
                            {profileFormik.touched.name &&
                            profileFormik.errors.name ? (
                              <div className="invalid-feedback">
                                {profileFormik.errors.name}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="profile-form-group">
                            <label>
                              Numri i telefonit{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className={`form-control ${
                                profileFormik.touched.phone &&
                                profileFormik.errors.phone
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name="phone"
                              value={profileFormik.values.phone}
                              onChange={profileFormik.handleChange}
                              onBlur={profileFormik.handleBlur}
                              placeholder="+383 -- --- ---"
                            />
                            {profileFormik.touched.phone &&
                            profileFormik.errors.phone ? (
                              <div className="invalid-feedback">
                                {profileFormik.errors.phone}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="profile-form-group">
                            <label>Email</label>
                            <input
                              type="email"
                              className="form-control opacity-50"
                              value={user?.email}
                              disabled
                            />
                          </div>
                        </div>

                        {/* <div className="col-md-12">
                          <div className="profile-form-group">
                            <label>
                              Passport <span className="text-danger">*</span>
                            </label>
                            <input
                              type="file"
                              className="form-control"
                              name="passport"
                              onChange={(event) =>
                                profileFormik.setFieldValue(
                                  "passport",
                                  event.currentTarget.files[0]
                                )
                              }
                              onBlur={profileFormik.handleBlur}
                              accept=".pdf,.jpg,.png"
                            />
                          </div>
                        </div> */}
                      </div>
                    </form>
                  </div>
                </div>
                <div className="profile-submit-btn mt-3">
                  <button onClick={profileFormik.handleSubmit} className="btn btn-primary">
                    Ndrysho të dhënat
                  </button>
                </div>
              </div>
            </div>

            {/* Password Change Section */}
            {/* <div className="col-lg-12 mt-5">
              <div className="settings-info">
                <div className="profile-info-grid">
                  <div className="profile-info-header">
                    <h5>Ndrysho Fjalëkalimin</h5>
                    <p>Ndryshoni fjalëkalimin tuaj për më shumë siguri.</p>
                  </div>
                  <div className="profile-inner">
                    <form onSubmit={passwordFormik.handleSubmit}>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="profile-form-group">
                            <label>
                              Old Password <span className="text-danger">*</span>
                            </label>
                            <input
                              type="password"
                              className={`form-control ${
                                passwordFormik.touched.oldPassword &&
                                passwordFormik.errors.oldPassword
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name="oldPassword"
                              value={passwordFormik.values.oldPassword}
                              onChange={passwordFormik.handleChange}
                              onBlur={passwordFormik.handleBlur}
                              placeholder="Enter Old Password"
                            />
                            {passwordFormik.touched.oldPassword &&
                            passwordFormik.errors.oldPassword ? (
                              <div className="invalid-feedback">
                                {passwordFormik.errors.oldPassword}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="profile-form-group">
                            <label>
                              New Password <span className="text-danger">*</span>
                            </label>
                            <input
                              type="password"
                              className={`form-control ${
                                passwordFormik.touched.newPassword &&
                                passwordFormik.errors.newPassword
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name="newPassword"
                              value={passwordFormik.values.newPassword}
                              onChange={passwordFormik.handleChange}
                              onBlur={passwordFormik.handleBlur}
                              placeholder="Enter New Password"
                            />
                            {passwordFormik.touched.newPassword &&
                            passwordFormik.errors.newPassword ? (
                              <div className="invalid-feedback">
                                {passwordFormik.errors.newPassword}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="profile-form-group">
                            <label>
                              Confirm New Password{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="password"
                              className={`form-control ${
                                passwordFormik.touched.confirmPassword &&
                                passwordFormik.errors.confirmPassword
                                  ? "is-invalid"
                                  : ""
                              }`}
                              name="confirmPassword"
                              value={passwordFormik.values.confirmPassword}
                              onChange={passwordFormik.handleChange}
                              onBlur={passwordFormik.handleBlur}
                              placeholder="Confirm New Password"
                            />
                            {passwordFormik.touched.confirmPassword &&
                            passwordFormik.errors.confirmPassword ? (
                              <div className="invalid-feedback">
                                {passwordFormik.errors.confirmPassword}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                      <div className="profile-submit-btn mt-3">
                      
                        <button type="submit" className="btn btn-primary">
                          Change Password
                        </button>
                      </div>
              </div>
            </div> */}
            {/* /Settings Details */}
          </div>
        </div>
      </div>
      {/* /Page Content */}
    </div>
  );
};

export default UserSettings;
