import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Link, useNavigate } from "react-router-dom";
import { CornerDownLeft } from "react-feather";
import { all_routes } from "../router/all_routes";
import { register } from "../../api/auth";

const routes = all_routes;

const SignUp = () => {
  const [isToggle, setIsToggle] = useState(false);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");

  // Formik setup with Yup validation schema including confirm password
  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      passport: null,
      email: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      phone: Yup.string().required("Phone is required"),
      passport: Yup.mixed().required("Passport is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      password: Yup.string().required("Password is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm password is required"),
    }),
    onSubmit: async (values) => {
      try {
        // Create a FormData object to handle file upload (passport)
        const formData = new FormData();
        formData.append("name", values.name);
        formData.append("phone", values.phone);
        formData.append("passport", values?.passport); // Assuming file upload
        formData.append("email", values.email);
        formData.append("password", values.password);
    
        // Call the register API
        await register(formData);
    
        // Redirect to login page after successful registration
        navigate(routes.login, { replace: true });
      } catch (error) {
        setErrorMessage(
          error.response?.data?.message || "Failed to register. Please try again."
        ); // Set error message based on response or generic error
      }
    },
    
  });

  return (
    <div>
      <div className="main-wrapper login-body">
        {/* Header */}
        <header className="log-header">
          <Link to={routes.homeOne}>
            <img
              className="img-fluid logo-dark h-16"
              src="/assets/img/logo.svg"
              alt="Logo"
            />
          </Link>
        </header>
        {/* /Header */}
        <div className="login-wrapper">
          <div className="loginbox">
            <div className="login-auth">
              <div className="login-auth-wrap">
                <div className="sign-group">
                  <Link to={routes.homeOne} className="btn sign-up">
                    <span>
                      <CornerDownLeft />
                    </span>{" "}
                    Kthehu prapa
                  </Link>
                </div>
                <h1>Regjistrohu</h1>
                {errorMessage && (
                  <div className="alert alert-danger" role="alert">
                    {errorMessage}
                  </div>
                )}
                <form onSubmit={formik.handleSubmit}>
                  <div className="input-block">
                    <label className="form-label">
                      Emri <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        formik.touched.name && formik.errors.name
                          ? "is-invalid"
                          : ""
                      }`}
                      name="name"
                      placeholder="Shkruaj emrin tuaj..."
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <div className="invalid-feedback">
                        {formik.errors.name}
                      </div>
                    ) : null}
                  </div>

                  <div className="input-block">
                    <label className="form-label">
                      Numri i telefonit <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="+383 -- --- ---"
                      className={`form-control ${
                        formik.touched.phone && formik.errors.phone
                          ? "is-invalid"
                          : ""
                      }`}
                      name="phone"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.phone}
                    />
                    {formik.touched.phone && formik.errors.phone ? (
                      <div className="invalid-feedback">
                        {formik.errors.phone}
                      </div>
                    ) : null}
                  </div>

                  <div className="input-block">
                    <label className="form-label">
                      Passport <span className="text-danger">*</span>
                    </label>
                    <input
                      type="file"
                      className={`form-control ${
                        formik.touched.passport && formik.errors.passport
                          ? "is-invalid"
                          : ""
                      }`}
                      name="passport"
                      onChange={(event) => {
                        formik.setFieldValue(
                          "passport",
                          event.currentTarget.files[0]
                        );
                      }}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.passport && formik.errors.passport ? (
                      <div className="invalid-feedback">
                        {formik.errors.passport}
                      </div>
                    ) : null}
                  </div>

                  <div className="input-block">
                    <label className="form-label">
                      Email <span className="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      className={`form-control ${
                        formik.touched.email && formik.errors.email
                          ? "is-invalid"
                          : ""
                      }`}
                      name="email"
                      placeholder="Shkruaj email-in tuaj..."
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className="invalid-feedback">
                        {formik.errors.email}
                      </div>
                    ) : null}
                  </div>

                  <div className="input-block">
                    <label className="form-label">
                    Fjalëkalimi <span className="text-danger">*</span>
                    </label>
                    <div
                      className="pass-group"
                    >
                      <input
                        type={isToggle ? "text" : "password"}
                        className={`form-control ${
                          formik.touched.password && formik.errors.password
                            ? "is-invalid"
                            : ""
                        }`}
                        name="password"
                        placeholder="Shkruaj fjalekalimin..."
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                      />
                      <span
                      onClick={() => setIsToggle(!isToggle)}

                        className={`fas toggle-password ${
                          isToggle ? "fa-eye" : "fa-eye-slash"
                        }`}
                      />
                    </div>
                    {formik.touched.password && formik.errors.password ? (
                      <div className="invalid-feedback">
                        {formik.errors.password}
                      </div>
                    ) : null}
                  </div>

                  <div className="input-block">
                    <label className="form-label">
                    Konfirmo fjalëkalimin <span className="text-danger">*</span>
                    </label>
                    <div
                      className="pass-group"
                    >
                      <input
                        type={isToggle ? "text" : "password"}
                        className={`form-control ${
                          formik.touched.confirmPassword &&
                          formik.errors.confirmPassword
                            ? "is-invalid"
                            : ""
                        }`}
                        placeholder="Konfirmo fjalëkalimin..."
                        name="confirmPassword"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.confirmPassword}
                      />
                      <span
                      onClick={() => setIsToggle(!isToggle)}

                        className={`fas toggle-password ${
                          isToggle ? "fa-eye" : "fa-eye-slash"
                        }`}
                      />
                    </div>
                    {formik.touched.confirmPassword &&
                    formik.errors.confirmPassword ? (
                      <div className="invalid-feedback">
                        {formik.errors.confirmPassword}
                      </div>
                    ) : null}
                  </div>

                  <button
                    type="submit"
                    className="btn btn-outline-light w-100 btn-size mt-1"
                  >
                   Regjistrohu
                  </button>
                </form>
                <div className="text-center dont-have">
                  Ke llogari?{" "}
                  <Link to={routes.login}>Kyçu tani</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Footer */}
        <footer className="log-footer">
          <div className="container-fluid">
            {/* Copyright */}
            <div className="copyright">
              <div className="copyright-text">
                <p>© 2023 Dreams Rent. All Rights Reserved.</p>
              </div>
            </div>
            {/* /Copyright */}
          </div>
        </footer>
        {/* /Footer */}
      </div>
    </div>
  );
};

export default SignUp;


