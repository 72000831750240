import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import * as Yup from "yup"; // Import Yup for validation
import { all_routes } from "../router/all_routes";
import { useListingsContext } from "../../context/ListingsContext";
import { getType, reserve } from "../../api/cars";
import moment from "moment";
import { useAuthContext } from "../../context/AuthContext";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import "dayjs/locale/sq";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { api_url } from "../../environment";
import { CornerDownLeft } from "react-feather";

const listingDetails = () => {
  const routes = all_routes;

  const bigImgSliderRef = useRef(null);
  const thumbnailSliderRef = useRef(null);

  const { values, setValues } = useListingsContext();
  const [error, setError] = useState("");
  const [data, setData] = useState();

  const { id } = useParams();
  const { authToken, sendToLogin } = useAuthContext();

  useEffect(() => {
    if (bigImgSliderRef.current && thumbnailSliderRef.current) {
      bigImgSliderRef.current.slickGoTo(0);
      thumbnailSliderRef.current.slickGoTo(0);
    }
  }, []);

  const navigate = useNavigate();

  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const sliderRef1 = useRef(null);
  const sliderRef2 = useRef(null);

  useEffect(() => {
    setNav1(sliderRef1.current);
    setNav2(sliderRef2.current);
  }, []);

  const settings1 = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: nav2 || undefined,
    ref: (slider) => (sliderRef1.current = slider),
  };

  const settings2 = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    focusOnSelect: true,
    asNavFor: nav1 || undefined,
    ref: (slider) => (sliderRef2.current = slider),
  };

  // Yup Validation Schema
  const validationSchema = Yup.object().shape({
    start: Yup.date().required("Data e pranimit është e domosdoshme"),
    end: Yup.date()
      .required("Data e dorëzimit është e domosdoshme")
    
      .test(
        "min-24-hours",
        "Data e dorëzimit duhet të jetë të paktën 24 orë pas datës së pranimit",
        function (value) {
          const { start } = this.parent;
          return moment(value).isAfter(moment(start).add(24, "hours"));
        }
      ),
  });

  // Formik setup
  const formik = useFormik({
    initialValues: {
      start: values?.start || null,
      end: values?.end || null,
    },
    validationSchema, // Apply validation schema here
    onSubmit: (values) => {
      console.log("Form values:", values);
    },
  });

  useEffect(() => {
    setValues(formik.values);
    const makeReq = async () => {
      if (!(formik.values.start && formik.values.end)) {
        setError("Plotësoni të dyja datat!");
      } else {
        setError("");
      }

      if (!data) {
        try {
          const response = await getType(id, formik.values);
          setData(response?.data);
          setError("");
        } catch {
          setError("Pati problem!");
        }
      }
    };
    makeReq();
  }, [formik.values]);

  const hours =
    formik.values.start && formik.values.end
      ? moment(formik.values.end).diff(moment(formik.values.start), "hours")
      : 0;

  const days_temp = Math.floor(hours / 24) + (hours % 24 >= 3 ? 1 : 0);
  const days = days_temp > 0 ? days_temp : 0;

  const reserveCar = async () => {
    if (!authToken) {
      sendToLogin();
      return;
    }
    if (formik.values.start && formik.values.end) {
      const promise = reserve(id, authToken, {
        date_from: moment(formik.values.start).format("YYYY-MM-DD HH:mm"),
        date_to: moment(formik.values.end).format("YYYY-MM-DD HH:mm"),
      });
      await toast.promise(promise, {
        loading: "Duke bërë rezervimin...",
        success: () => {
          setTimeout(() => {
            navigate(routes.userBookings);
          }, 2000);
          return "U rezervua më sukses";
        },
        error: (err) => {
          console.error(err);
          setError("Vetura është e zenë në keto data!");
          return "Vetura është e zenë në keto data!";
        },
      });
    } else {
      setError("Plotësoni të dyja datat!");
    }
  };

  const images = useMemo(() => {
    let gallery = data?.images ? JSON.parse(data?.images) : [];
    gallery = gallery?.map((image) => {
      return `${api_url}uploads/${image}`;
    });

    return [`${api_url}/storage/uploads/${data?.thumbnail}`, ...gallery];
  }, [data]);

  return (
    <div className="main-wrapper">
      {/* <Breadcrumbs title={data?.name} subtitle="Listings" /> */}

      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="sq">
        <>
          {/* Detail Page Head*/}
          <section className="product-detail-head">
            <div className="container">
              <div className="detail-page-head">
                <div className="detail-headings">
                  <div className="star-rated">
                    <div className="camaro-info">
                      <h3>{data?.name}</h3>
                      <div className="camaro-location">
                        <Link to={-1} className="btn flex gap-2 sign-up">
                          <span>
                            <CornerDownLeft />
                          </span>{" "}
                          Kthehu prapa
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* /Detail Page Head*/}
        </>

        <section className="section product-details">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="detail-product">
                  <div className="slider detail-bigimg">
                    <Slider {...settings1}>
                      {images?.map((image, index) => (
                        <div className="product-img" key={index}>
                          <img src={image} />
                        </div>
                      ))}
                    </Slider>
                  </div>
                  <div className="slider slider-nav-thumbnails">
                    <Slider {...settings2}>
                      {images?.map((image, index) => (
                        <div key={index}>
                          <img src={image} />
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
                <div className="review-sec mb-0">
                  <div className="review-header">
                    <h4>Përshkrimi</h4>
                  </div>
                  <div className="description-list">
                    <p>{data?.description}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 theiaStickySidebar">
                <div className="stickybar">
                  <div className="review-sec mt-0 p-4">
                    <div className="review-header">
                      <h4>Rezervo</h4>
                    </div>
                    <div className="location-content">
                      <div className="">
                        <div className=" active show search-box" id="delivery">
                          <form onSubmit={formik.handleSubmit}>
                            <ul>
                              <li className="column-group-main">
                                <div className="input-block mb-1 mt-2">
                                  <label>Data e pranimit</label>
                                </div>
                                <div className="input-block-wrap w-full">
                                  <div className="input-block date-widget w-full">
                                    <div className="group-img">
                                      <DateTimePicker
                                        className="w-full !border-none !outline-none"
                                        value={
                                          formik.values.start &&
                                          dayjs(values.start)
                                        }
                                        views={[
                                          "year",
                                          "day",
                                          "hours",
                                          "minutes",
                                        ]}
                                        onChange={(value) =>
                                          formik.setFieldValue(
                                            "start",
                                            new Date(value)
                                          )
                                        }
                                      />
                                      <span>
                                        <i className="feather icon-calendar" />
                                      </span>
                                      {formik.errors.start &&
                                      formik.touched.start ? (
                                        <div className="error-message">
                                          {formik.errors.start}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </li>

                              <li className="column-group-main">
                                <div className="input-block mb-1">
                                  <label>Data e dorëzimit</label>
                                </div>
                                <div className="input-block-wrap w-full">
                                  <div className="input-block date-widget w-full">
                                    <div className="group-img">
                                      <DateTimePicker
                                        className="w-full !border-none !outline-none"
                                        value={
                                          formik.values.end && dayjs(values.end)
                                        }
                                        views={[
                                          "year",
                                          "day",
                                          "hours",
                                          "minutes",
                                        ]}
                                        onChange={(value) =>
                                          formik.setFieldValue(
                                            "end",
                                            new Date(value)
                                          )
                                        }
                                      />
                                      <span>
                                        <i className="feather icon-calendar" />
                                      </span>
                                      {formik.errors.end &&
                                      formik.touched.end ? (
                                        <div className="error-message">
                                          {formik.errors.end}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </li>

                              <p className="error-message text-red-500">
                                {error}
                              </p>

                              <div className="review-header !mt-6">
                                <h4>Qmimi</h4>
                              </div>
                              <div className="price-list">
                                <div className="price-item">
                                  <p>Për ditë</p>
                                  <p className="">
                                    {days} x {data?.price || 0}€
                                  </p>
                                </div>

                                <div className="price-item">
                                  <p>Totali</p>
                                  <p className="!text-xl font-bold text-primary">
                                    {days * data?.price || 0}€
                                  </p>
                                </div>
                              </div>

                              <li className="column-group-last my-2">
                                <div className="input-block mb-0">
                                  <div className="search-btn">
                                    <button
                                      onClick={reserveCar}
                                      className="btn cursor-pointer btn-primary py-3 check-available w-100"
                                    >
                                      Rezervo tani
                                    </button>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </LocalizationProvider>
    </div>
  );
};

export default listingDetails;
