import React from "react";
import { Link } from "react-router-dom";

// import CountUp from "react-countup";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { useQuery } from "@tanstack/react-query";

import "aos/dist/aos.css";

import ImageWithBasePath from "../../../core/data/img/ImageWithBasePath";
import { all_routes } from "../../router/all_routes";
import { getTypes } from "../../../api/cars";

const routes = all_routes;
export const Car = ({ item, props }) => {
 
  return (
    <div {...props} className="col-lg-4 col-md-6 col-12">
      <div className="listing-item">
        <div className="listing-img">
          <div className="img-slider ">
            <Link to={routes.listingDetails + item?.id}>
              <ImageWithBasePath
                src={item?.thumbnail}
                className="img-fluid"
              />
            </Link>
          </div>
        
        </div>
        <div className="listing-content px-[10px] w-full pb-3 pt-1">
          <div className="listing-features w-full">
            <div className="list-rating items-center !flex !justify-between gap-2 !w-full">
              <h3 className="listing-title !mb-0">
                <Link to={routes.listingDetails + item?.id}>{item?.name}</Link>
              </h3>
              <p className="!text-lg !text-red-600 !font-bold">
                {item?.price}€{" "}
                <span className="font-normal !text-neutral-500">/ dita</span>
              </p>
            </div>
            <p className="text-sm line-clamp-3 mt-2 !text-neutral-500 !font-light">
              {item.description}
            </p>
          </div>

          <div className="listing-button !rounded-none">
            <Link
              to={routes.listingDetails + item?.id}
              className="btn btn-order !rounded-none"
            >
              <span>
                <i className="feather icon-calendar me-2" />
              </span>
              Rezervo tani
            </Link>
          </div>
        </div>
        {item.featured && (
          <div className="feature-text">
            <span className="bg-danger">Featured</span>
          </div>
        )}
      </div>
    </div>
  );
};

const Cars = () => {
  // const testimonials = testimonialsData;

  const { data } = useQuery(["random-cars"], () => getTypes({}));

  return (
    <>
      <section className="section popular-services popular-explore bg-white">
        <div className="container">
          {/* Heading title*/}
          <div className="section-heading" data-aos="fade-down">
            <h2>Shiko veturat</h2>
            <p>
              Shiko disa nga veturat
            </p>
          </div>
          {/* /Heading title */}

          <div className="tab-content">
            <div className="tab-pane active" id="Carmazda">
              <div className="row">
                {data?.data?.map((item, index) => (
                  <Car key={index} item={item} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Cars;
