import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useListingsContext } from "../../../context/ListingsContext";
import moment from "moment";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import 'moment/locale/sq';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const Search = () => {
  const navigate = useNavigate();
  const { values, setValues } = useListingsContext();

  const initialValues = {
    model: values?.model || "",
    start: values?.start,
    end: values?.end,
  };

  const validationSchema = Yup.object().shape({
    start: Yup.date().required("Data e pranimit është e domosdoshme"),
    end: Yup.date()
      .required("Data e dorëzimit është e domosdoshme")
      .min(Yup.ref('start'), "Data e dorëzimit nuk mund të jetë para datës së pranimit")
      .test(
        "min-24-hours",
        "Data e dorëzimit duhet të jetë të paktën 24 orë pas datës së pranimit",
        function (value) {
          const { start } = this.parent;
          return moment(value).isAfter(moment(start).add(24, 'hours'));
        }
      ),
  });

  return (
    <div className="section-search">
      <div className="container">
        <div className="search-box-banner">
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="sq">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                setValues(values);
                navigate("/listings");
              }}
            >
              {({ setFieldValue, values, errors, touched }) => (
                <Form>
                  <ul className="align-items-center gap-y-3">
                    <li className="column-group-main">
                      <div className="input-block w-full">
                        <label>Lloji i veturës <span className="!font-[400] text-neutral-600">(opsionale)</span></label>
                        <div className="group-img">
                          <Field
                            name="model"
                            type="text"
                            className={`form-control !mb-0 ${
                              errors.model && touched.model ? "is-invalid" : ""
                            }`}
                            placeholder="Lloji i veturës..."
                          />
                          <span>
                            <i className="fa fa-car" />
                          </span>
                        </div>
                      </div>
                    </li>
                    <li className="column-group-main">
                      <div className="input-block">
                        <label>Data e pranimit</label>
                      </div>
                      <div className="input-block-wrap w-full">
                        <div className="input-block date-widget w-full">
                          <div className="group-img">
                            <DateTimePicker
                              className="w-full !border-none !outline-none"
                              value={values.start && moment(values.start)}
                              views={['year', 'day', 'hours', 'minutes']}
                              onChange={(value) =>
                                setFieldValue("start", new Date(value))
                              }
                            />
                            <span>
                              <i className="feather icon-calendar" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="column-group-main">
                      <div className="input-block">
                        <label>Data e dorëzimit</label>
                      </div>
                      <div className="input-block-wrap w-full">
                        <div className="input-block date-widget w-full">
                          <div className="group-img">
                            <DateTimePicker
                              className="w-full !border-none !outline-none"
                              value={values.end && moment(values.end)}
                              views={['year', 'day', 'hours', 'minutes']}
                              onChange={(value) =>
                                setFieldValue("end", new Date(value))
                              }
                            />
                            <span>
                              <i className="feather icon-calendar" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="column-group-last">
                      <div className="input-block">
                        <div className="search-btn">
                          <button className="btn search-button" type="submit">
                            <i className="fa fa-search" aria-hidden="true" />
                            Kërko
                          </button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </Form>
              )}
            </Formik>
          </LocalizationProvider>
        </div>
      </div>
    </div>
  );
};

export default Search;
