import React from "react";

import Breadcrumbs from "../common/breadcrumbs";

import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import { Link } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { all_routes } from "../router/all_routes";
import { useQuery } from "@tanstack/react-query";
import { getReservations } from "../../api/cars";
import { useAuthContext } from "../../context/AuthContext";
import moment from "moment";

const UserBookings = () => {
  const routes = all_routes;

  // const [currentPage, setCurrentPage] = useState(1);
  // const totalPages = 10;

  // const handlePrevPage = () => {
  //   if (currentPage > 1) {
  //     setCurrentPage((prev) => prev - 1);
  //   }
  // };

  // const handleNextPage = () => {
  //   if (currentPage < totalPages) {
  //     setCurrentPage((prev) => prev + 1);
  //   }
  // };

  // const getVisiblePages = () => {
  //   const visiblePages = [];
  //   const maxVisiblePages = 5;

  //   if (totalPages <= maxVisiblePages) {
  //     // If total pages are less than or equal to max visible, show all
  //     for (let i = 1; i <= totalPages; i++) {
  //       visiblePages.push(i);
  //     }
  //   } else {
  //     // Always include the first and last pages
  //     visiblePages.push(1);
  //     if (currentPage > 3) visiblePages.push("...");

  //     const startPage = Math.max(2, currentPage - 1);
  //     const endPage = Math.min(totalPages - 1, currentPage + 1);

  //     for (let i = startPage; i <= endPage; i++) {
  //       visiblePages.push(i);
  //     }

  //     if (currentPage < totalPages - 2) visiblePages.push("...");
  //     visiblePages.push(totalPages);
  //   }

  //   return visiblePages;
  // };

  const { authToken } = useAuthContext();

  const { data } = useQuery(["reservations", authToken], () =>
    getReservations(authToken)
  );

  const carName = (res) => {
    return (
      <div className="table-avatar">
        <Link
          to={routes.listingDetails + res.type?.id}
          className="avatar avatar-lg flex-shrink-0"
        >
          <ImageWithBasePath
            className="avatar-img"
            src={res?.type?.thumbnail}
            alt="Booking"
          />
        </Link>
        <div className="table-head-name flex-grow-1">
          <Link to={routes.listingDetails + res.type?.id}>
            {res?.type?.name}
          </Link>
        </div>
      </div>
    );
  };

  const BookingId = (res) => {
    return <p>{res.id}</p>;
  };

  // const action = () => {
  //   return (
  //     <div className="dropdown dropdown-action">
  //       <Link
  //         to="#"
  //         className="dropdown-toggle"
  //         data-bs-toggle="dropdown"
  //         aria-expanded="false"
  //       >
  //         <i className="fas fa-ellipsis-vertical" />
  //       </Link>
  //       <div className="dropdown-menu dropdown-menu-end">
  //         <Link
  //           className="dropdown-item"
  //           to="#"
  //           data-bs-toggle="modal"
  //           data-bs-target="#delete_modal"
  //         >
  //           <i className="feather icon-trash-2" /> Cancel
  //         </Link>
  //       </div>
  //     </div>
  //   );
  // };

  return (
    <div className="!min-h-[85vh]">
      <Breadcrumbs title="Rezervimet" subtitle="Rezervimet" />

      <div className="content">
        <div className="container">
          {/* Content Header */}
          {/* <div className="content-header">
            <h4>Rezervimet</h4>
          </div> */}
          {/* /Content Header */}
          {/* Sort By */}

          {/* Sort By */}
          <div className="row">
            {/* All Bookings */}
            <div className="col-lg-12 d-flex">
              <div className="card book-card flex-fill mb-0">
                <div className="card-header">
                  <div className="row align-items-center">
                    <div className="col-md-5">
                      <h4>
                        Rezervimet <span>{data?.data?.length}</span>
                      </h4>
                    </div>
                    <div className="col-md-7 text-md-end">
                      <div className="table-search">
                        <Link to={routes.listingList} className="btn btn-add">
                          <i className="feather icon-plus-circle" />
                          Shto Rezervim
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive dashboard-table">
                    <DataTable
                      className="table datatable"
                      value={data?.data}
                      currentPageReportTemplate="{first}"
                    >
                      <Column
                        field="bookingId"
                        header="ID#"
                        body={BookingId}
                      ></Column>
                      <Column
                        field="carName"
                        header="Vetura"
                        body={carName}
                      ></Column>

                      <Column
                        field="dates"
                        header="Pranimi - Dorëzimi"
                        body={(data) => (
                          <p>
                            {moment(data.date_from).format("DD/MM/YYYY HH:mm")}{" "}
                            <span className="text-red-700">|</span>{" "}
                            {moment(data.date_to).format("DD/MM/YYYY HH:mm")}
                          </p>
                        )}
                      ></Column>
                      <Column
                        field="bookedOn"
                        header="Koha e rezervimit"
                        body={(data) => <p>{data.created_at}</p>}
                      ></Column>
                      <Column
                        field="total"
                        header="Qmimi"
                        body={(data) => <p>{data.price}€</p>}
                      ></Column>

                      {/* <Column
                        field="action"
                        header="Action"
                        body={action}
                      ></Column> */}
                    </DataTable>
                  </div>
                  <div className="table-footer">
                    <div className="row">
                      <div className="col-md-6">
                        <div id="tablelength" />
                      </div>
                      <div className="col-md-6 text-md-end">
                        <div id="tablepage" />
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="blog-pagination">
                  <nav>
                    <ul className="pagination page-item justify-content-center">
                      <li
                        className={`previtem ${currentPage === 1 && "disabled"}`}
                      >
                        <p
                          className="page-link text-neutral-800"
                          onClick={handlePrevPage}
                        >
                          <i className="fas fa-regular fa-arrow-left me-2" />{" "}
                          Prev
                        </p>
                      </li>
                      <li className="justify-content-center pagination-center">
                        <div className="page-group">
                          <ul>
                            {getVisiblePages().map((page, i) =>
                              page === "..." ? (
                                <p
                                  key={`pg-${page}`}
                                  className="text-slate-300 mr-3"
                                >
                                  ...
                                </p>
                              ) : (
                                <li
                                  key={i}
                                  className={`page-item ${
                                    currentPage === page ? "active" : ""
                                  }`}
                                >
                                  <Link
                                    className={`page-link ${
                                      currentPage === page ? "active" : ""
                                    }`}
                                    to="#"
                                    onClick={() => setCurrentPage(page)}
                                  >
                                    {page}
                                  </Link>
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      </li>
                      <li
                        className={`nextlink ${
                          currentPage === totalPages && "disabled"
                        }`}
                      >
                        <p
                          className="page-link text-neutral-800"
                          onClick={handleNextPage}
                        >
                          Next{" "}
                          <i className="fas fa-regular fa-arrow-right ms-2" />
                        </p>
                      </li>
                    </ul>
                  </nav>
                </div> */}
              </div>
            </div>
            {/* /All Bookings */}
          </div>
          {/* /Dashboard */}
        </div>
      </div>

      <div
        className="modal new-modal fade"
        id="delete_modal"
        data-keyboard="false"
        data-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="delete-action">
                <div className="delete-header">
                  <h4>Delete Booking</h4>
                  <p>Are you sure want to delete?</p>
                </div>
                <div className="modal-btn">
                  <div className="row">
                    <div className="col-6">
                      <Link
                        to="#"
                        data-bs-dismiss="modal"
                        className="btn btn-secondary w-100"
                      >
                        Delete
                      </Link>
                    </div>
                    <div className="col-6">
                      <Link
                        to="#"
                        data-bs-dismiss="modal"
                        className="btn btn-primary w-100"
                      >
                        Cancel
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserBookings;
