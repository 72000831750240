import React, { useEffect } from "react";

import AOS from 'aos';
import 'aos/dist/aos.css';


const Footer = () => {

  AOS.init();
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  const handleScroll = () => {
    AOS.refresh();
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
        {/* Footer */}
        <footer className="footer">	
        {/* Footer Top */}	
        
        {/* /Footer Top */}
        {/* Footer Bottom */}
        <div className="footer-bottom">
          <div className="container">
            {/* Copyright */}
            <div className="copyright">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <div className="copyright-text">
                    <p>© 2024 Dreams Rent. All Rights Reserved.</p>
                  </div>
                </div>
               
              </div>
            </div>
            {/* /Copyright */}
          </div>
        </div>
        {/* /Footer Bottom */}			
      </footer>
      {/* /Footer */}
    </>
  );
};

export default Footer;
