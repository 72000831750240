import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CornerDownLeft } from "react-feather";
import { all_routes } from "../router/all_routes";
import { login, getUser } from "../../api/auth";
import { useAuthContext } from "../../context/AuthContext";

const routes = all_routes;

const Login = () => {
  const [isToggle, setIsToggle] = useState(false);
  const [errorMessage, setErrorMessage] = useState(""); // State for error message
  const { setToken, setUser, prevRoute } = useAuthContext();

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
     
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: async (values) => {
      try {
        const response = await login({
          username: values.email,
          password: values.password,
        });

        const token = response.token_type + " " + response.access_token;
        setToken(token);

        const userResponse = await getUser(token);
        setUser(userResponse);

        navigate(prevRoute ? prevRoute : -1 || routes.homeOne);
      } catch (error) {
        setErrorMessage("Emaili ose fjalëkalimi janë të pasakta!"); // Set error message for wrong credentials
      }
    },
  });

  return (
    <div className="main-wrapper login-body">
      {/* Header */}
      <header className="log-header">
        <Link to={routes.homeOne}>
          <img
            className="img-fluid logo-dark h-16"
            src="/assets/img/logo.svg"
            alt="Logo"
          />
        </Link>
      </header>
      {/* /Header */}
      <div className="login-wrapper">
        <div className="loginbox">
          <div className="login-auth">
            <div className="login-auth-wrap">
              <div className="sign-group">
                <Link to={routes.homeOne} className="btn sign-up">
                  <span>
                    <CornerDownLeft />
                  </span>{" "}
                 Shko prapa
                </Link>
              </div>
              {/* <h1>Kyqu</h1> */}
              {/* <p className="account-subtitle">
                We will send a confirmation code to your email.
              </p> */}
              <form onSubmit={formik.handleSubmit}>
                {errorMessage && (
                  <div className="alert alert-danger" role="alert">
                    {errorMessage}
                  </div>
                )}
                <div className="input-block">
                  <label className="form-label">
                    Email <span className="text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    placeholder=""
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="text-danger">{formik.errors.email}</div>
                  ) : null}
                </div>
                <div className="input-block">
                  <label className="form-label">
                    Fjalëkalimi <span className="text-danger">*</span>
                  </label>
                  <div
                    className="pass-group"
                    
                  >
                    <input
                      type={isToggle ? "text" : "password"}
                      className="form-control pass-input"
                      name="password"
                      placeholder=""
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                    />
                    <span
                    onClick={() => setIsToggle(!isToggle)}
                      className={`fas toggle-password ${
                        isToggle ? "fa-eye" : "fa-eye-slash"
                      }`}
                    />
                  </div>
                  {formik.touched.password && formik.errors.password ? (
                    <div className="text-danger">{formik.errors.password}</div>
                  ) : null}
                </div>

                {/* <div className="input-block">
                  <Link className="forgot-link" to={routes.forgotPassword}>
                    Forgot Password ?
                  </Link>
                </div>
                <div className="input-block m-0">
                  <label className="custom_check d-inline-flex">
                    <span>Remember me</span>
                    <input
                      type="checkbox"
                      name="remember"
                      onChange={formik.handleChange}
                      checked={formik.values.remember}
                    />
                    <span className="checkmark" />
                  </label>
                </div> */}
                <button
                  type="submit"
                  className="btn btn-outline-light w-100 btn-size mt-1"
                >
                  Kyqu
                </button>
                {/* <div className="login-or">
                  <span className="or-line" />
                  <span className="span-or-log">
                    Or, log in with your email
                  </span>
                </div> */}
                {/* Social Login */}
                {/* <div className="social-login">
                  <Link
                    to="#"
                    className="d-flex align-items-center justify-content-center input-block btn google-login w-100"
                  >
                    <span>
                      <ImageWithBasePath
                        src="assets/img/icons/google.svg"
                        className="img-fluid"
                        alt="Google"
                      />
                    </span>
                    Log in with Google
                  </Link>
                </div> */}
                {/* <div className="social-login">
                  <Link
                    to="#"
                    className="d-flex align-items-center justify-content-center input-block btn google-login w-100"
                  >
                    <span>
                      <ImageWithBasePath
                        src="assets/img/icons/facebook.svg"
                        className="img-fluid"
                        alt="Facebook"
                      />
                    </span>
                    Log in with Facebook
                  </Link>
                </div> */}
                {/* /Social Login */}
                <div className="text-center mb-4 dont-have">
                  Nuk ke llogari? <Link to={routes.register}>Registrohu</Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Footer */}
      <footer className="log-footer">
        <div className="container-fluid">
          {/* Copyright */}
          <div className="copyright">
            <div className="copyright-text">
              <p>© 2023 Dreams Rent. All Rights Reserved.</p>
            </div>
          </div>
          {/* /Copyright */}
        </div>
      </footer>
      {/* /Footer */}
    </div>
  );
};

export default Login;
