import React, { createContext, useContext, useState,  } from "react";


export const ListingsContext = createContext();

export const useListingsContext = () => useContext(ListingsContext);

export const ListingsContextProvider = ({ children }) => {
  const [values, setValues] = useState(null);

  return (
    <ListingsContext.Provider
      value={{
        values,
        setValues
      }}
    >
      {children}
    </ListingsContext.Provider>
  );
};
