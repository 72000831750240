import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import Breadcrumbs from "../common/breadcrumbs";
// import { Dropdown } from "primereact/dropdown";
import { Link } from "react-router-dom";
import "rc-slider/assets/index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Search from "../home/home-one/Search";
import { useQuery } from "@tanstack/react-query";
import { getFreeTypes } from "../../api/cars";
import { useListingsContext } from "../../context/ListingsContext";
import { Car } from "../home/home-one/Cars";
import moment from "moment";

// Debounce function to delay API call
function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

const ListingsList = () => {
  const { values } = useListingsContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [types, setTypes] = useState([]);

  const formik = useFormik({
    initialValues: {
      year: [],
      capacity: [],
      minPrice: null,
      maxPrice: null,
      transmission: [],
    },
    onSubmit: () => {
      setCurrentPage(1); // Reset to page 1 when filters are applied
    },
    onReset: () => {
      setCurrentPage(1); // Reset to page 1 when filters are reset
    },
  });

  const params = {
    ...(values?.start && {
      date_from: moment(values.start).format("YYYY-MM-DD HH:mm"),
    }),
    ...(values?.end && {
      date_to: moment(values.end).format("YYYY-MM-DD HH:mm"),
    }),
    ...(values?.model && { model: values.model }),

    ...(formik.values.year?.length > 0 && { years: formik.values.year }),
    ...(formik.values.capacity?.length > 0 && {
      capacity: formik.values.capacity,
    }),
    ...(formik.values?.minPrice && { price_from: formik.values?.minPrice }),
    ...(formik.values?.maxPrice && { price_to: formik.values?.maxPrice }),
    ...(formik.values.transmission?.length > 0 && {
      transmission: formik.values.transmission,
    }),
  };

  const debouncedParams = useDebounce(params, 500); // Debounce params with a 500ms delay

  const { data } = useQuery(
    ["listings", currentPage, debouncedParams],
    () => getFreeTypes(debouncedParams, currentPage),
    { refetchOnMount: true }
  );

  useEffect(() => {
    if (data?.last_page) {
      setTotalPages(data?.last_page);
      if (data?.last_page < currentPage) {
        setCurrentPage(1);
      }
    }
    if(data?.data){
      setTypes(data?.data)
    }
  }, [data]);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const getVisiblePages = () => {
    const visiblePages = [];
    const maxVisiblePages = 5;

    if (totalPages <= maxVisiblePages) {
      // If total pages are less than or equal to max visible, show all
      for (let i = 1; i <= totalPages; i++) {
        visiblePages.push(i);
      }
    } else {
      // Always include the first and last pages
      visiblePages.push(1);
      if (currentPage > 3) visiblePages.push("...");

      const startPage = Math.max(2, currentPage - 1);
      const endPage = Math.min(totalPages - 1, currentPage + 1);

      for (let i = startPage; i <= endPage; i++) {
        visiblePages.push(i);
      }

      if (currentPage < totalPages - 2) visiblePages.push("...");
      visiblePages.push(totalPages);
    }

    return visiblePages;
  };

  return (
    <div className="listing-page">
      <Breadcrumbs title="Veturat" subtitle="Listings" />

      <Search />

      <div className="sort-section">
        <div className="container">
          <div className="sortby-sec">
            <div className="sorting-div">
              <div className="row d-flex align-items-center">
                <div className="col-xl-4 col-lg-3 col-sm-12 col-12">
                  <div className="count-search">
                    <p>
                      Duke shfaqur {data?.data?.length} prej {data?.total} Veturave
                    </p>
                  </div>
                </div>
                {/* <div className="col-xl-8 col-lg-9 col-sm-12 col-12">
                  <div className="product-filter-group">
                    <div className="sortbyset">
                      <ul>
                        <li>
                          <span className="sortbytitle">Sort By </span>
                          <div className="sorting-select select-two">
                            <Dropdown
                              value={formik.values.sortBy}
                              onChange={(e) =>
                                formik.setFieldValue("sortBy", e.value)
                              }
                              options={[
                                "Newest",
                                "Price: Low to High",
                                "Price: High to Low",
                                "Year: New to Old",
                              ]}
                              placeholder="Newest"
                              className="w-100"
                            />
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="section car-listing pt-0">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-4 col-sm-12 col-12 theiaStickySidebar">
              <div className="stickybar">
                <form
                  onSubmit={formik.handleSubmit}
                  onReset={formik.handleReset}
                  className="sidebar-form"
                >
                  <div className="accord-list">
                    {[
                      {
                        id: "price",
                        title: "Qmimi",
                        type: "range",
                        min: null,
                        max: null,
                      },

                      // {
                      //   id: "year",
                      //   title: "Year",
                      //   type: "checkbox",
                      //   options: [
                      //     "2024",
                      //     "2022",
                      //     "2021",
                      //     "2020",
                      //     "2019",
                      //     "2018",
                      //     "2017",
                      //     "2016",
                      //     "2015",
                      //     "2014",
                      //     "2013",
                      //   ],
                      // },
                      // {
                      //   id: "capacity",
                      //   title: "Ulese",
                      //   type: "checkbox",
                      //   options: ["5", "7"],
                      // },
                      // {
                      //   id: "transmission",
                      //   title: "Transmission",
                      //   type: "checkbox",
                      //   options: ["Manual", "Semi Automatic", "Automatic"],
                      // },
                    ].map((filter) => (
                      <div
                        key={filter.id}
                        className="accordion"
                        id={`accordion${filter.id}`}
                      >
                        <div
                          className="card-header-new"
                          id={`heading${filter.id}`}
                        >
                          <h6 className="filter-title">
                            <Link
                              to="#"
                              className="w-100"
                              data-bs-toggle="collapse"
                              data-bs-target={`#collapse${filter.id}`}
                              aria-expanded="false"
                              aria-controls={`collapse${filter.id}`}
                            >
                              {filter.title}
                              <span className="float-end">
                                <i className="fa-solid fa-chevron-down" />
                              </span>
                            </Link>
                          </h6>
                        </div>
                        <div
                          id={`collapse${filter.id}`}
                          className="collapse show"
                          aria-labelledby={`heading${filter.id}`}
                          data-bs-parent={`#accordion${filter.id}`}
                        >
                          <div className="card-body-chat">
                            {filter.type === "checkbox" &&
                              filter.options.map((option) => (
                                <label
                                  key={option}
                                  className="custom_check w-100"
                                >
                                  <input
                                    type="checkbox"
                                    name={filter.id}
                                    value={option}
                                    onChange={formik.handleChange}
                                    checked={formik.values[filter.id].includes(
                                      option
                                    )}
                                  />
                                  <span className="checkmark" /> {option}
                                </label>
                              ))}

                            {filter.type === "range" && (
                              <div className="filter-range mb-3">
                                <div className="flex">
                                  <div className="flex flex-1">
                                    <input
                                      type="number"
                                      name="minPrice"
                                      className="form-control"
                                      placeholder="Minimalja"
                                      value={formik.values.minPrice}
                                      onChange={formik.handleChange}
                                    />
                                  </div>
                                  <div className="mx-2 flex items-center justify-center">
                                    -
                                  </div>
                                  <div className="flex flex-1">
                                    <input
                                      type="number"
                                      name="maxPrice"
                                      className="form-control"
                                      placeholder="Maksimalja"
                                      value={formik.values.maxPrice}
                                      onChange={formik.handleChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <p
                    onClick={() => formik.resetForm()}
                    className="d-inline-flex mb-2 mt-3 align-items-center justify-content-center btn w-100 text-red-700"
                  >
                    Largo Filterin
                  </p>
                </form>
              </div>
            </div>

            <div className="col-xl-9 col-lg-8 col-sm-12 col-12">
              <div className="row">
                {types?.map((item, index) => (
                  <Car key={index} item={item} />
                ))}
              </div>
              {/* Pagination */}
              <div className="blog-pagination">
                <nav>
                  <ul className="pagination page-item justify-content-center">
                    <li
                      className={`previtem ${currentPage === 1 && "disabled"}`}
                    >
                      <Link
                        className="page-link"
                        to="#"
                        onClick={handlePrevPage}
                      >
                        <i className="fas fa-regular fa-arrow-left me-2" /> Prapa
                      </Link>
                    </li>
                    <li className="justify-content-center pagination-center">
                      <div className="page-group">
                        <ul>
                          {getVisiblePages().map((page, i) =>
                            page === "..." ? (
                              <p
                                key={`pg-${page}`}
                                className="text-slate-300 mr-3"
                              >
                                ...
                              </p>
                            ) : (
                              <li
                                key={i}
                                className={`page-item ${
                                  currentPage === page ? "active" : ""
                                }`}
                              >
                                <Link
                                  className={`page-link ${
                                    currentPage === page ? "active" : ""
                                  }`}
                                  to="#"
                                  onClick={() => setCurrentPage(page)}
                                >
                                  {page}
                                </Link>
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                    </li>
                    <li
                      className={`nextlink ${
                        currentPage === totalPages && "disabled"
                      }`}
                    >
                      <Link
                        className="page-link"
                        to="#"
                        onClick={handleNextPage}
                      >
                        Para{" "}
                        <i className="fas fa-regular fa-arrow-right ms-2" />
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ListingsList;
