import React, { createContext, useContext, useState, useEffect } from "react";
import { getUser } from "../api/auth";
import { useLocation, useNavigate } from "react-router-dom";

export const AuthContext = createContext();

export const useAuthContext = () => useContext(AuthContext);

export const AuthContextProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const [prevRoute, setPrevRoute] = useState(null);
  const navigate = useNavigate();
  const {pathname} = useLocation();

  const sendToLogin = () => {
    setPrevRoute(pathname);
    navigate("/authentication/login");
  };

  const setToken = (token) => {
    localStorage.setItem("authToken", token);
    setAuthToken(token);
  };

  const removeToken = () => {
    localStorage.removeItem("authToken");
    setAuthToken(null);
    setUser(null);
  };

  const getToken = async () => {
    const token = localStorage.getItem("authToken");
    setAuthToken(token);

    try {
      if (token) {
        const userResponse = await getUser(token);
        setUser(userResponse);
      }
    } catch (error) {
      removeToken();
    }
    setLoading(false);
    return token;
  };
  

  useEffect(() => {
    getToken();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        authToken,
        setToken,
        getToken,
        removeToken,
        user,
        setUser,
        loading,
        prevRoute,
        sendToLogin
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
