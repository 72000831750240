import requestApi from "./requestApi";

export const login = async (params) =>
  await requestApi({
    url: "/oauth/token",
    method: "post",
    params: {
      grant_type: "password",
      scope: "",
      client_id: 2,
      client_secret: "24gwlQmkxeq2L8KaMAsz2IY2IjVMy592feysr9Dg",
      ...params,
    },
  });

  
export const logout = async () =>
  await requestApi({ url: "/logout", method: "post" });

export const register = async (params) =>
  await requestApi({
    url: "/api/client_user/store",
    method: "post",
    params,
    headers: {
      "Content-Type": "multipart/form-data", // Set the header for file upload
     
    },
  });

export const getUser = async (token) =>
  await requestApi({
    url: "/api/client_user",
    method: "get",
    headers: {
      Authorization: token,
      ContentType: "application/json",
     
    },
  });


  export const updateUser = async (token, params) =>
    await requestApi({
      url: "/api/client_user/update",
      method: "post",
      params,
      headers: {
        Authorization: token,
        ContentType: "application/json",
     
      },
    });
  