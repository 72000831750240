import React from "react";
import { Link } from "react-router-dom";

// import CountUp from "react-countup";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";

import "aos/dist/aos.css";

import { all_routes } from "../../router/all_routes";
// import { testimonialsData } from "../../../core/data/json/testimonials_data";
import Footer from "../../common/footer";
import Header from "../../common/header";
import Search from "./Search";
import Cars from "./Cars";

const HomeOne = () => {
  const routes = all_routes;
  // const testimonials = testimonialsData;
  // const [selectedItems, setSelectedItems] = useState(Array(10).fill(false));

  // const handleItemClick = (index: number) => {
  //   setSelectedItems((prevSelectedItems) => {
  //     const updatedSelectedItems = [...prevSelectedItems];
  //     updatedSelectedItems[index] = !updatedSelectedItems[index];
  //     return updatedSelectedItems;
  //   });
  // };

  // const imgslideroption = {
  //   dots: true,
  //   nav: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   responsive: [
  //     {
  //       breakpoint: 1000,
  //       settings: {
  //         slidesToShow: 3,
  //       },
  //     },
  //     {
  //       breakpoint: 700,
  //       settings: {
  //         slidesToShow: 2,
  //       },
  //     },
  //     {
  //       breakpoint: 550,
  //       settings: {
  //         slidesToShow: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 0,
  //       settings: {
  //         slidesToShow: 1,
  //       },
  //     },
  //   ],
  // };
  // const rentalslideroption = {
  //   dots: false,
  //   nav: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   responsive: [
  //     {
  //       breakpoint: 1000,
  //       settings: {
  //         slidesToShow: 3,
  //       },
  //     },
  //     {
  //       breakpoint: 700,
  //       settings: {
  //         slidesToShow: 2,
  //       },
  //     },
  //     {
  //       breakpoint: 550,
  //       settings: {
  //         slidesToShow: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 0,
  //       settings: {
  //         slidesToShow: 1,
  //       },
  //     },
  //   ],
  // };

  return (
    <>
      <Header />

      <section className="banner-section banner-slider">
        <div className="container">
          <div className="home-banner">
            <div className="row align-items-center">
              <div className="col-lg-6" data-aos="fade-down">
                <p className="explore-text">
                  <span className="!text-[#fb2626]">
                    <i className="fa-solid fa-thumbs-up me-2 !text-[#fb2626] "></i>
                  </span>
                  100% e besueshme
                </p>
                <h1 className="text-6xl mt-4 mb-4">
                  <span className="!text-[#fb2626]">Zgjidhja Juaj</span> <br />
                  për Udhtime Komode!
                </h1>
                <p className="lg:pr-12 xl:pr-12 md:pr-0">
                  Swiss Rent A Car ofron vetura ekonomike dhe luksoze, duke
                  përshtatur çdo zgjedhje sipas nevojave të klientit. Me
                  fleksibilitet në periudha qiraje dhe shërbime të dedikuara,
                  udhëtimi bëhet i lehtë dhe komod.
                </p>
                <div className="view-all">
                  <Link
                    to={routes.listingList}
                    className="btn btn-view d-inline-flex align-items-center "
                  >
                    Shiko veturat{" "}
                    <span>
                      <i className="feather icon-arrow-right ms-2" />
                    </span>
                  </Link>
                </div>
              </div>
              <div className="col-lg-6" data-aos="fade-down">
                <div className="banner-imgs">
                  <img
                    src="./assets/img/car.png"
                    className="img-fluid aos"
                    alt="bannerimage"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Search />

      <Cars />

      {/* <section className="section popular-services">
        <div className="container">
        
          <div className="section-heading" data-aos="fade-down">
            <h2>Recommended Car Rental deals</h2>
            <p>Here are some versatile options that cater to different needs</p>
          </div>
        
          <div className="row">
            <div className="popular-slider-group">
              <div className=" rental-deal-slider ">
                <Slider {...rentalslideroption} className="rental-slider">
                 
                  <div className="rental-car-item">
                    <div className="listing-item mb-0">
                      <div className="listing-img">
                        <Link to={routes.listingDetails}>
                          <img
                            src="assets/img/cars/rental-car-01.jpg"
                            className="img-fluid"
                            alt="Toyota"
                          />
                        </Link>
                        <div
                          className="fav-item justify-content-end"
                          key={55}
                          onClick={() => handleItemClick(55)}
                        >
                          <Link
                            to="#"
                            className={`fav-icon ${
                              selectedItems[55] ? "selected" : ""
                            }`}
                          >
                            <i className="feather  icon-heart" />
                          </Link>
                        </div>
                      </div>
                      <div className="listing-content">
                        <div className="listing-features">
                          <div className="fav-item-rental">
                            <div className="featured-text">
                              $400<span>/day</span>
                            </div>
                          </div>
                          <div className="list-rating">
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <span>(5.0)</span>
                          </div>
                          <h3 className="listing-title">
                            <Link to={routes.listingDetails}>
                              BMW 640 XI Gran Turismo
                            </Link>
                          </h3>
                        </div>
                        <div className="listing-details-group">
                          <ul>
                            <li>
                              <span>
                                <img
                                  src="assets/img/icons/car-parts-01.svg"
                                  alt="Auto"
                                />
                              </span>
                              <p>Auto</p>
                            </li>
                            <li>
                              <span>
                                <img
                                  src="assets/img/icons/car-parts-02.svg"
                                  alt="10 KM"
                                />
                              </span>
                              <p>10 KM</p>
                            </li>
                            <li>
                              <span>
                                <img
                                  src="assets/img/icons/car-parts-03.svg"
                                  alt="Petrol"
                                />
                              </span>
                              <p>Diesel</p>
                            </li>
                          </ul>
                          <ul>
                            <li>
                              <span>
                                <img
                                  src="assets/img/icons/car-parts-04.svg"
                                  alt="Power"
                                />
                              </span>
                              <p>Power</p>
                            </li>
                            <li>
                              <span>
                                <img
                                  src="assets/img/icons/car-parts-07.svg"
                                  alt={2018}
                                />
                              </span>
                              <p>AC</p>
                            </li>
                            <li>
                              <span>
                                <img
                                  src="assets/img/icons/car-parts-06.svg"
                                  alt="Persons"
                                />
                              </span>
                              <p>5 Persons</p>
                            </li>
                          </ul>
                        </div>
                        <div className="listing-button">
                          <Link
                            to={routes.listingDetails}
                            className="btn btn-order"
                          >
                            <span>
                              <i className="feather icon-calendar me-2" />
                            </span>
                            Rent Now
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
               
                  <div className="rental-car-item">
                    <div className="listing-item mb-0">
                      <div className="listing-img">
                        <Link to={routes.listingDetails}>
                          <img
                            src="assets/img/cars/rental-car-02.jpg"
                            className="img-fluid"
                            alt="Toyota"
                          />
                        </Link>
                        <div
                          className="fav-item justify-content-end"
                          key={56}
                          onClick={() => handleItemClick(56)}
                        >
                          <Link
                            to="#"
                            className={`fav-icon ${
                              selectedItems[56] ? "selected" : ""
                            }`}
                          >
                            <i className="feather  icon-heart" />
                          </Link>
                        </div>
                      </div>
                      <div className="listing-content">
                        <div className="listing-features">
                          <div className="fav-item-rental">
                            <div className="featured-text">
                              $210<span>/day</span>
                            </div>
                          </div>
                          <div className="list-rating">
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <span>(5.0)</span>
                          </div>
                          <h3 className="listing-title">
                            <Link to={routes.listingDetails}>
                              Camz Ferrari Portofino M
                            </Link>
                          </h3>
                        </div>
                        <div className="listing-details-group">
                          <ul>
                            <li>
                              <span>
                                <img
                                  src="assets/img/icons/car-parts-01.svg"
                                  alt="Auto"
                                />
                              </span>
                              <p>Auto</p>
                            </li>
                            <li>
                              <span>
                                <img
                                  src="assets/img/icons/car-parts-02.svg"
                                  alt="10 KM"
                                />
                              </span>
                              <p>30 KM</p>
                            </li>
                            <li>
                              <span>
                                <img
                                  src="assets/img/icons/car-parts-03.svg"
                                  alt="Petrol"
                                />
                              </span>
                              <p>Diesel</p>
                            </li>
                          </ul>
                          <ul>
                            <li>
                              <span>
                                <img
                                  src="assets/img/icons/car-parts-04.svg"
                                  alt="Power"
                                />
                              </span>
                              <p>Power</p>
                            </li>
                            <li>
                              <span>
                                <img
                                  src="assets/img/icons/car-parts-07.svg"
                                  alt={2018}
                                />
                              </span>
                              <p>AC</p>
                            </li>
                            <li>
                              <span>
                                <img
                                  src="assets/img/icons/car-parts-06.svg"
                                  alt="Persons"
                                />
                              </span>
                              <p>5 Persons</p>
                            </li>
                          </ul>
                        </div>
                        <div className="listing-button">
                          <Link
                            to={routes.listingDetails}
                            className="btn btn-order"
                          >
                            <span>
                              <i className="feather icon-calendar me-2" />
                            </span>
                            Rent Now
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                
                  <div className="rental-car-item">
                    <div className="listing-item mb-0">
                      <div className="listing-img">
                        <div className="img-slider listing-page-slider">
                          <Slider {...imgslideroption}>
                            <div className="slide-images">
                              <Link to={routes.listingDetails}>
                                <img
                                  src="assets/img/cars/rental-car-03.jpg"
                                  className="img-fluid"
                                  alt="Toyota"
                                />
                              </Link>
                            </div>
                            <div className="slide-images">
                              <Link to={routes.listingDetails}>
                                <img
                                  src="assets/img/cars/rental-car-03-slider1.jpg"
                                  className="img-fluid"
                                  alt="Toyota"
                                />
                              </Link>
                            </div>
                            <div className="slide-images">
                              <Link to={routes.listingDetails}>
                                <img
                                  src="assets/img/cars/rental-car-03-slider2.jpg"
                                  className="img-fluid"
                                  alt="Toyota"
                                />
                              </Link>
                            </div>
                            <div className="slide-images">
                              <Link to={routes.listingDetails}>
                                <img
                                  src="assets/img/cars/rental-car-03-slider3.jpg"
                                  className="img-fluid"
                                  alt="Toyota"
                                />
                              </Link>
                            </div>
                          </Slider>
                        </div>
                        <div
                          className="fav-item justify-content-end"
                          key={57}
                          onClick={() => handleItemClick(57)}
                        >
                          <Link
                            to="#"
                            className={`fav-icon ${
                              selectedItems[57] ? "selected" : ""
                            }`}
                          >
                            <i className="feather  icon-heart" />
                          </Link>
                        </div>
                      </div>
                      <div className="listing-content">
                        <div className="listing-features">
                          <div className="fav-item-rental">
                            <div className="featured-text">
                              $380<span>/day</span>
                            </div>
                          </div>
                          <div className="list-rating">
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <span>(5.0)</span>
                          </div>
                          <h3 className="listing-title">
                            <Link to={routes.listingDetails}>
                              Mercedes-Benz
                            </Link>
                          </h3>
                        </div>
                        <div className="listing-details-group">
                          <ul>
                            <li>
                              <span>
                                <img
                                  src="assets/img/icons/car-parts-01.svg"
                                  alt="Auto"
                                />
                              </span>
                              <p>Auto</p>
                            </li>
                            <li>
                              <span>
                                <img
                                  src="assets/img/icons/car-parts-02.svg"
                                  alt="10 KM"
                                />
                              </span>
                              <p>30 KM</p>
                            </li>
                            <li>
                              <span>
                                <img
                                  src="assets/img/icons/car-parts-03.svg"
                                  alt="Petrol"
                                />
                              </span>
                              <p>Diesel</p>
                            </li>
                          </ul>
                          <ul>
                            <li>
                              <span>
                                <img
                                  src="assets/img/icons/car-parts-04.svg"
                                  alt="Power"
                                />
                              </span>
                              <p>Power</p>
                            </li>
                            <li>
                              <span>
                                <img
                                  src="assets/img/icons/car-parts-07.svg"
                                  alt={2018}
                                />
                              </span>
                              <p>AC</p>
                            </li>
                            <li>
                              <span>
                                <img
                                  src="assets/img/icons/car-parts-06.svg"
                                  alt="Persons"
                                />
                              </span>
                              <p>5 Persons</p>
                            </li>
                          </ul>
                        </div>
                        <div className="listing-button">
                          <Link
                            to={routes.listingDetails}
                            className="btn btn-order"
                          >
                            <span>
                              <i className="feather icon-calendar me-2" />
                            </span>
                            Rent Now
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
            
                  <div className="rental-car-item">
                    <div className="listing-item mb-0">
                      <div className="listing-img">
                        <Link to={routes.listingDetails}>
                          <img
                            src="assets/img/cars/rental-car-04.jpg"
                            className="img-fluid"
                            alt="Toyota"
                          />
                        </Link>
                        <div
                          className="fav-item justify-content-end"
                          key={58}
                          onClick={() => handleItemClick(58)}
                        >
                          <Link
                            to="#"
                            className={`fav-icon ${
                              selectedItems[58] ? "selected" : ""
                            }`}
                          >
                            <i className="feather  icon-heart" />
                          </Link>
                        </div>
                      </div>
                      <div className="listing-content">
                        <div className="listing-features">
                          <div className="fav-item-rental">
                            <span className="featured-text">$250/day</span>
                          </div>
                          <div className="list-rating">
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <span>(4.5)</span>
                          </div>
                          <h3 className="listing-title">
                            <Link to={routes.listingDetails}>Range Rover</Link>
                          </h3>
                        </div>
                        <div className="listing-details-group">
                          <ul>
                            <li>
                              <span>
                                <img
                                  src="assets/img/icons/car-parts-01.svg"
                                  alt="Auto"
                                />
                              </span>
                              <p>Auto</p>
                            </li>
                            <li>
                              <span>
                                <img
                                  src="assets/img/icons/car-parts-02.svg"
                                  alt="10 KM"
                                />
                              </span>
                              <p>28 KM</p>
                            </li>
                            <li>
                              <span>
                                <img
                                  src="assets/img/icons/car-parts-03.svg"
                                  alt="Petrol"
                                />
                              </span>
                              <p>Petrol</p>
                            </li>
                          </ul>
                          <ul>
                            <li>
                              <span>
                                <img
                                  src="assets/img/icons/car-parts-04.svg"
                                  alt="Power"
                                />
                              </span>
                              <p>Power</p>
                            </li>
                            <li>
                              <span>
                                <img
                                  src="assets/img/icons/car-parts-07.svg"
                                  alt={2018}
                                />
                              </span>
                              <p>AC</p>
                            </li>
                            <li>
                              <span>
                                <img
                                  src="assets/img/icons/car-parts-06.svg"
                                  alt="Persons"
                                />
                              </span>
                              <p>5 Persons</p>
                            </li>
                          </ul>
                        </div>
                        <div className="listing-button">
                          <Link
                            to={routes.listingDetails}
                            className="btn btn-order"
                          >
                            <span>
                              <i className="feather icon-calendar me-2" />
                            </span>
                            Rent Now
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
            
                </Slider>
              </div>
            </div>
          </div>

          
          <div className="view-all text-center" data-aos="fade-down">
            <Link
              to={routes.listingGrid}
              className="btn btn-view d-inline-flex align-items-center"
            >
              Go to all Cars{" "}
              <span>
                <i className="feather icon-arrow-right ms-2" />
              </span>
            </Link>
          </div>
      
        </div>
      </section> */}

      <section className="section faq-section bg-light-primary">
        <div className="container">
          {/* Heading title*/}
          <div className="section-heading" data-aos="fade-down">
            <h2>Frequently Asked Questions </h2>
            <p>Find answers to your questions from our previous answers</p>
          </div>
          {/* /Heading title */}
          <div className="faq-info">
            <div className="faq-card bg-white" data-aos="fade-down">
              <h4 className="faq-title">
                <Link
                  className="collapseds"
                  data-bs-toggle="collapse"
                  to="#faqOne"
                  aria-expanded="true"
                >
                  How old do I need to be to rent a car?
                </Link>
              </h4>
              <div id="faqOne" className="card-collapse collapse show">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </p>
              </div>
            </div>
            <div className="faq-card bg-white" data-aos="fade-down">
              <h4 className="faq-title">
                <Link
                  className="collapsed"
                  data-bs-toggle="collapse"
                  to="#faqTwo"
                  aria-expanded="false"
                >
                  What documents do I need to rent a car?
                </Link>
              </h4>
              <div id="faqTwo" className="card-collapse collapse">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </p>
              </div>
            </div>
            <div className="faq-card bg-white" data-aos="fade-down">
              <h4 className="faq-title">
                <Link
                  className="collapsed"
                  data-bs-toggle="collapse"
                  to="#faqThree"
                  aria-expanded="false"
                >
                  What types of vehicles are available for rent?
                </Link>
              </h4>
              <div id="faqThree" className="card-collapse collapse">
                <p>
                  We offer a diverse fleet of vehicles to suit every need,
                  including compact cars, sedans, SUVs and luxury vehicles. You
                  can browse our selection online or contact us for assistance
                  in choosing the right vehicle for you
                </p>
              </div>
            </div>
            <div className="faq-card bg-white" data-aos="fade-down">
              <h4 className="faq-title">
                <Link
                  className="collapsed"
                  data-bs-toggle="collapse"
                  to="#faqFour"
                  aria-expanded="false"
                >
                  Can I rent a car with a debit card?
                </Link>
              </h4>
              <div id="faqFour" className="card-collapse collapse">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </p>
              </div>
            </div>
            <div className="faq-card bg-white" data-aos="fade-down">
              <h4 className="faq-title">
                <Link
                  className="collapsed"
                  data-bs-toggle="collapse"
                  to="#faqFive"
                  aria-expanded="false"
                >
                  What is your fuel policy?
                </Link>
              </h4>
              <div id="faqFive" className="card-collapse collapse">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </p>
              </div>
            </div>
            <div className="faq-card bg-white" data-aos="fade-down">
              <h4 className="faq-title">
                <Link
                  className="collapsed"
                  data-bs-toggle="collapse"
                  to="#faqSix"
                  aria-expanded="false"
                >
                  Can I add additional drivers to my rental agreement?
                </Link>
              </h4>
              <div id="faqSix" className="card-collapse collapse">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </p>
              </div>
            </div>
            <div className="faq-card bg-white" data-aos="fade-down">
              <h4 className="faq-title">
                <Link
                  className="collapsed"
                  data-bs-toggle="collapse"
                  to="#faqSeven"
                  aria-expanded="false"
                >
                  What happens if I return the car late?
                </Link>
              </h4>
              <div id="faqSeven" className="card-collapse collapse">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default HomeOne;
