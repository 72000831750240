import { api_url } from "../environment";

import axios from "axios";

const Axios = axios.create({
  baseURL: api_url,
  // withCredentials: true,
});

export default async function requestApi({
  url,
  params,
  method = "get",
  ...props
}) {
  const response = await Axios.request({
    method,
    url,
    data: params,
    withCredentials: false,

    ...props,
  });

  if (response.status === 200) {
    return response.data;
  } else {
    console.error(response);
  }
}
