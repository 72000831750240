import requestApi from "./requestApi";

export const getTypes = async (params) =>
  await requestApi({
    url: "/api/get_types",
    method: "get",
    params,
  });

export const getFreeTypes = async (params, page) =>
  await requestApi({
    url: `/api/types`,
    method: "post",
    params: {
      page,
      ...params,
    },
  });

export const getType = async (id, params) =>
  await requestApi({
    url: `/api/get_type/${id}`,
    method: "get",
    params,
  });

export const reserve = async (id, token, params) =>
  await requestApi({
    url: `/api/reserve_by_type/${id}`,
    method: "post",
    params,
    headers: {
      Authorization: token,
      ContentType: "application/json",
      
    },
  });

export const getReservations = async (token, params) =>
  await requestApi({
    url: `/api/my_reservations`,
    method: "get",
    params,
    headers: {
      Authorization: token,
      ContentType: "application/json",
    
    },
  });
